<template>
  <div class="w-full bg-white shadow-lg rounded-lg relative p-4 break-words">
    <div class="text-xl font-bold text-gray-800">{{ event.theme }}</div>
    <div class="text-lg text-gray-400">{{ event.pastorName }}</div>
    <div class="flex justify-between">
      <div class="text-lg text-red-400">{{ time }}</div>
      <div class="text-lg text-gray-400">{{ date }}</div>
    </div>
    <table v-if="isKids">
      <tr>
        <td class="text-base text-gray-800 align-top">Kelas</td>
        <td class="text-base text-gray-800 align-top">:</td>
        <td class="text-base text-gray-800 align-top">{{ event.className }}</td>
      </tr>
    </table>
    <div
      v-if="isPublic && completeInformation"
      class="flex flex-col my-3">
      <table>
        <tr>
          <td class="text-base text-gray-800 align-top">Pendamping</td>
          <td class="text-base text-gray-800 align-top">:</td>
          <td class="text-base text-gray-800 align-top">{{ event.pastorAssistantName }}</td>
        </tr>
        <tr>
          <td class="text-base text-gray-800 align-top">Penyambut</td>
          <td class="text-base text-gray-800 align-top">:</td>
          <td class="text-base text-gray-800 align-top">{{ event.greeterNames }}</td>
        </tr>
        <tr>
          <td class="text-base text-gray-800 align-top">Pdn Suara</td>
          <td class="text-base text-gray-800 align-top">:</td>
          <td class="text-base text-gray-800 align-top">{{ event.choirName }}</td>
        </tr>
        <tr>
          <td class="text-base text-gray-800 align-top">Pmd Musik</td>
          <td class="text-base text-gray-800 align-top">:</td>
          <td class="text-base text-gray-800 align-top">{{ event.chantingGuideName }}</td>
        </tr>
        <tr>
          <td class="text-base text-gray-800 align-top">Pemusik</td>
          <td class="text-base text-gray-800 align-top">:</td>
          <td class="text-base text-gray-800 align-top">{{ event.bandName }}</td>
        </tr>
        <tr>
          <td class="text-base text-gray-800 align-top">Multimedia</td>
          <td class="text-base text-gray-800 align-top">:</td>
          <td class="text-base text-gray-800 align-top">{{ event.multimediaName }}</td>
        </tr>
      </table>
    </div>
    <div
      v-if="isTeen && completeInformation"
      class="flex flex-col my-3">
      <table>
        <tr>
          <td class="text-base text-gray-800 align-top">Info</td>
          <td class="text-base text-gray-800 align-top">:</td>
          <td class="text-base text-gray-800 align-top">{{ event.info }}</td>
        </tr>
        <tr>
          <td class="text-base text-gray-800 align-top">Pendamping</td>
          <td class="text-base text-gray-800 align-top">:</td>
          <td class="text-base text-gray-800 align-top">{{ event.pastorAssistantName }}</td>
        </tr>
        <tr>
          <td class="text-base text-gray-800 align-top">Pemusik</td>
          <td class="text-base text-gray-800 align-top">:</td>
          <td class="text-base text-gray-800 align-top">{{ event.bandName }}</td>
        </tr>
        <tr>
          <td class="text-base text-gray-800 align-top">Singer</td>
          <td class="text-base text-gray-800 align-top">:</td>
          <td class="text-base text-gray-800 align-top">{{ event.singerName }}</td>
        </tr>
        <tr>
          <td class="text-base text-gray-800 align-top">Liturgos</td>
          <td class="text-base text-gray-800 align-top">:</td>
          <td class="text-base text-gray-800 align-top">{{ event.liturgosName }}</td>
        </tr>
      </table>
    </div>
    <div class="flex justify-between mt-4">
      <div
        @click="handleScheduleClick"
        class="text-sm text-blue-500 cursor-pointer">Mengikuti Kebaktian di {{ event.serviceType }}</div>
      <div
        v-if="!isKids"
        @click="toggleCompleteInformation"
        class="text-sm text-blue-500 cursor-pointer">{{ moreInfoLabel }}</div>
    </div>
  </div>
</template>

<script>
import { timeFormater, dateFormater } from '@/utils/time'
import { scheduleConfig } from '@/config'
import { goUrl } from '@/utils/browser'

export default {
  name: 'ScheduleCard',
  data () {
    return {
      completeInformation: false
    }
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    time () {
      return timeFormater(this.event.eventDate)
    },
    date () {
      return dateFormater(this.event.eventDate)
    },
    moreInfoLabel () {
      return this.completeInformation ? 'Sembunyikan' : 'Selengkapnya'
    },
    eventType () {
      return this.event.type
    },
    isPublic () {
      return this.eventType === scheduleConfig.type.public
    },
    isTeen () {
      return this.eventType === scheduleConfig.type.teen
    },
    isKids () {
      return this.eventType === scheduleConfig.type.kids
    },
    serviceValue () {
      return this.event?.serviceValue ?? null
    }
  },
  methods: {
    toggleCompleteInformation () {
      this.completeInformation = !this.completeInformation
    },
    handleScheduleClick () {
      if (!this.serviceValue) return
      if (this.serviceValue.indexOf('http') > -1 || this.serviceValue.indexOf('www') > -1) return goUrl(this.serviceValue)
      return this.$router.push(this.serviceValue)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
